import baseComponent from "@/scripts/baseComponent";
import authApi from '@/api/common/auth';

export default {

    extends: baseComponent,

    props: {

    },

    data() {
        return {
            defaultValue: '',
            confirmText: '',
            listData: {},

            batchPoint: false,
            rewardAll: 5,

            batchCountry: false,
            enabledAllCountry: false,

            batchIP: false,
            enabledAllIP: false,
        };
    },

    computed: {

    },

    created() {

    },

    mounted() {
        this.getData();
    },

    methods: {

        // Close this pop-up
        closeThisPopup() {
            this.listData = {};
            this.closePopup('system-settings-policy-verified-email');
        },

        // Batch
        batch() {
            this.listData.forEach(element => {
                if (this.batchPoint) element.rewardActivatedEmail = this.rewardAll;
                if (this.batchCountry) element.applyRewardActivatedEmail = this.enabledAllCountry;
                if (this.batchIP) element.applyRewardActivatedEmailByIp = this.enabledAllIP;
            });
        },

        // Restore
        restore() {
            this.listData = JSON.parse(this.defaultValue);
        },

        // Get data
        getData() {
            this.feedStatusLoading(0);
            authApi.policyVerifiedEmailView()
                .then((res) => {
                    try {
                        this.defaultValue = JSON.stringify(res.data);
                        this.listData = res.data;
                        this.feedStatusLoading(1);
                    } catch (error) {
                        console.log("getData -> error", error)
                        this.feedStatusLoading(3);
                        // this.$store.dispatch("common/showUnkownError", error, "Error");
                    }
                })
                .catch(error => {
                    try {
                        if (error.name === 'Error') {
                            if (this.$route.path !== '/login') {
                                // setTimeout(() => {
                                //     location.href = "/login";
                                // }, 2000);
                            }
                        } else {
                            switch (error.errorCode) {
                                default:
                                    console.log("getData -> error", error)
                                    // this.$store.dispatch("common/showUnkownError", error, "Error");
                                    break;
                            }
                        }
                    } catch (error) {
                        console.log("getData -> error", error)
                        // this.$store.dispatch("common/showUnkownError", error, "Error");
                    }
                    this.feedStatusLoading(3);
                });
        },

        // Edit
        edit() {
            this.feedStatusLoading(0);
            this.listData.forEach(element => {
                element.rewardActivatedEmail = parseFloat(element.rewardActivatedEmail);
            });
            let request = {
                listData: this.listData,
                deviceInformation: JSON.stringify(this.getInformationClient()),
            }
            authApi.policyVerifiedEmailModify(request)
                .then((res) => {
                    try {
                        this.showSuccessToast(`Successful edit policy verified email !`);
                        setTimeout(() => {
                            this.feedStatusLoading(1);
                            this.closePopup('system-settings-policy-verified-email');
                        }, 500);
                    } catch (error) {
                        console.log("getData -> error", error)
                        this.feedStatusLoading(3);
                        // this.$store.dispatch("common/showUnkownError", error, "Error");
                    }
                })
                .catch(error => {
                    try {
                        if (error.name === 'Error') {
                            if (this.$route.path !== '/login') {
                                // setTimeout(() => {
                                //     location.href = "/login";
                                // }, 2000);
                            }
                        } else {
                            switch (error.errorCode) {
                                default:
                                    console.log("getData -> error", error)
                                    // this.$store.dispatch("common/showUnkownError", error, "Error");
                                    break;
                            }
                        }
                    } catch (error) {
                        console.log("getData -> error", error)
                        // this.$store.dispatch("common/showUnkownError", error, "Error");
                    }
                    this.feedStatusLoading(3);
                });
        }
    }
} 